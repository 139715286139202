/* eslint-disable react/jsx-no-target-blank, no-nested-ternary */
import React from "react";
import { FormErrors } from "redux-form";
import { Trans } from "react-i18next";
import { has, isEmpty } from "lodash";

import { getPortalLink } from "core";

import { ROLE_TYPE, typeOption } from "core/types";
import { Validation } from "core/helpers/index";

import i18n from "locales/i18n";

import { FormValues } from "store/routines/users/manageLocal";
import { FormValuesFolder } from "store/routines/folders/manage";

const Validate: { [key: string]: any } = {};

interface IMaxFileSize {
  role: ROLE_TYPE;
}

/**
 * Function fill needed for translate, when user change language
 */
function fill() {
  Validate.required = (value: string) => (value ? undefined : i18n.t("validate_required"));

  /**
   * Validate FormFile field for max file size
   * @param file: file when field change
   * @param allValues: default validate param (no need for this function)
   * @param role: proms need include user role
   */
  Validate.maxFileSize = (file: File, allValues: any, { role }: IMaxFileSize) => {
    if (!file) {
      return undefined;
    }

    const fileSize = file.size / 1000000;

    switch (role) {
      case ROLE_TYPE.FREE_USER:
        if (fileSize > 1) {
          return (
            <Trans i18nKey="validate_file_size_limit_free" size={1}>
              <a target="_blank" href={`${getPortalLink(window.location.host)}/login`}>
                Upgrade
              </a>
            </Trans>
          );
        }
        break;
      case ROLE_TYPE.STANDARD_USER:
        if (fileSize > 2) {
          return i18n.t("validate_file_size_limit_standard", { size: 2 });
        }
        break;
      case ROLE_TYPE.USER:
      case ROLE_TYPE.ADMIN:
      case ROLE_TYPE.MANAGER:
        if (fileSize > 5) {
          return i18n.t("validate_file_size_limit_business", { size: 5 });
        }
        break;
    }
    return undefined;
  };

  Validate.maxLength = (max: number) => (value: string | any[]) =>
    value && value.length > max ? i18n.t("validate_max_length", { max }) : undefined;

  Validate.equalLength = (len: number) => (value: string | any[]) => {
    if (!value || value.length === 0) {
      return undefined;
    }

    if (value.length !== len) {
      return i18n.t("validate_equal_length", { equal: len });
    }

    return undefined;
  };

  Validate.equalLength4 = Validate.equalLength(4);
  Validate.equalLength8 = Validate.equalLength(8);

  Validate.maxLengthGroupTitle = (max: number) => (value: string | any[]) =>
    value && value.length > max ? i18n.t("validate_max_length_group", { max }) : undefined;

  Validate.maxLengthGroupTitleAzure = (max: number) => (value: any) =>
    value && value.value.substring(0, value.value.lastIndexOf(",")).length > max ? i18n.t("validate_max_length_group", { max }) : undefined;

  Validate.emoji = (value: string) => (/\p{Extended_Pictographic}/u.test(value) ? i18n.t("validate_no_imoji") : undefined);

  Validate.requiredSelect = (select: typeOption) =>
    !select || !select.value || select.value === "" ? i18n.t("validate_required") : undefined;

  Validate.minLength = (min: number) => (value: string | any[]) =>
    value && value.length < min ? i18n.t("validate_min_length", { min }) : undefined;

  Validate.withoutSpace = (value: string) => (value && /[ ]/gm.test(value) ? i18n.t("validate_without_space") : undefined);

  Validate.minValue = (min: number) => (value: number) => value && value < min ? i18n.t("validate_min_value", { min }) : undefined;

  Validate.number = (value: string | number) => (value && isNaN(Number(value)) ? i18n.t("validate_number") : undefined);

  Validate.onlyDigit = (value: string) => (value && !/^\d+$/.test(value) ? i18n.t("validate_number") : undefined);
  Validate.iban = (value: string) => (value && isNaN(Number(value[0])) ? undefined : i18n.t("validate_letters_iban"));

  Validate.creditCardNumber = (value: string) =>
    /^[0-9]{13,19}$/.test(value.replace(/\s/g, "")) ? undefined : i18n.t("validate_credit_card_number");

  Validate.creditCardExpiration = (value: string) =>
    /^\d{2}\/\d{2}$/.test(value) || isEmpty(value) ? undefined : i18n.t("validate_credit_card_expiration");
  Validate.creditCardExpirationMonth = (value: string) =>
    isEmpty(value) || (Number(value.substring(0, 2)) <= 12 && Number(value.substring(0, 2)) !== 0o0)
      ? undefined
      : i18n.t("validate_expiration_date");

  Validate.creditCardCVV = (value: string) =>
    isEmpty(value) ? undefined : /^(\d{3}|\d{4})$/.test(value) ? undefined : i18n.t("validate_credit_card_cvv");

  Validate.onlyLetters = (value: string) => (/^[A-Za-z\s]*$/.test(value) ? undefined : i18n.t("validate_letters"));

  Validate.onlyLettersAll = (value: string) =>
    /^((?![0-9.,!?:;_|+\-*\\/=%°@&#§$"'`¨^ˇ()[\]<>{}])[\S\s])+$/.test(value) ? undefined : i18n.t("validate_letters");
  Validate.onlyLettersAndNumberAll = (value: string) =>
    /^((?![.,!?:;_|+\-*\\/=%°@&#§$"'`¨^ˇ()[\]<>{}])[\S\s])+$/.test(value) ? undefined : i18n.t("validate_letters_and_number");

  Validate.invalidCharacters = (value: string) => (/^[A-Za-z0-9\s]*$/.test(value) ? undefined : i18n.t("validate_letters_and_number"));

  Validate.notSpace = (value: string) => (/\s/.test(value) ? i18n.t("validate_not_space") : undefined);

  Validate.email = (value: string) =>
    value && !/^[A-Z0-9._%#+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value) ? i18n.t("validate_email") : undefined;

  Validate.port = (value: string) => ((value && value.length < 6) || value === undefined ? undefined : i18n.t("validate_port"));

  Validate.ip = (value: string) =>
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      value,
    )
      ? undefined
      : i18n.t("validate_ip");

  Validate.hostname1123 = (value: string) =>
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/.test(value)
      ? undefined
      : i18n.t("validate_hostname");
  Validate.hostname952 = (value: string) =>
    /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$/.test(value)
      ? undefined
      : i18n.t("validate_hostname");

  Validate.hostname_ip = (value: string) =>
    Validate.ip(value) === undefined || Validate.hostname1123(value) === undefined || Validate.hostname952(value) === undefined
      ? undefined
      : i18n.t("validate_hostname_ip");

  Validate.validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  Validate.aol = (value: string) => (value && /.+@aol\.com/.test(value) ? i18n.t("validate_aol") : undefined);

  Validate.tooOld = (value: number) => (value && value > 65 ? i18n.t("validate_too_old") : undefined);

  Validate.passwordTooWeak = (value: string) =>
    value && !/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[a-z]).*$/i.test(value) ? i18n.t("validate_password_too_weak") : undefined;

  Validate.oldPassword = (value: string, allValues: any) => {
    const min = 8;
    if (value && value.length < min) {
      return i18n.t("validate_min_length", { min });
    }
    if (allValues.oldPassword && allValues.password) {
      return allValues.oldPassword === allValues.password ? i18n.t("validate_passwords_duplication") : undefined;
    }
    return undefined;
  };
  Validate.passwordsMatch = (value: string, { password, repeatPassword }) => {
    return password !== repeatPassword ? i18n.t("validate_passwords_match") : undefined;
  };

  Validate.minLength8 = Validate.minLength(8);

  Validate.minValue3 = Validate.minValue(3);
  Validate.minValue18 = Validate.minValue(18);

  Validate.maxLengthGroup = Validate.maxLengthGroupTitle(256);
  Validate.maxLengthGroupAzure = Validate.maxLengthGroupTitleAzure(256);

  Validate.maxLength8 = Validate.maxLength(8);
  Validate.maxLength50 = Validate.maxLength(50);
  Validate.maxLength100 = Validate.maxLength(100);
  Validate.maxLength255 = Validate.maxLength(255);
  Validate.maxLength512 = Validate.maxLength(512);
  Validate.maxLength2048 = Validate.maxLength(2048);
  Validate.maxLength3072 = Validate.maxLength(3072);
}

fill();
i18n.on("languageChanged init", () => fill());

export const validationsFormLocalUser = (values: FormValues, props): FormErrors<FormValues> => {
  const errors: FormErrors<FormValues> = {};

  if (Validation.required(values.firstName)) {
    errors.firstName = Validation.required(values.firstName);
  }

  if (Validation.required(values.lastName)) {
    errors.lastName = Validation.required(values.lastName);
  }

  if (Validation.required(values.roles)) {
    errors.roles = Validation.required(values.roles);
  }

  if (Validation.required(values.locale)) {
    errors.locale = Validation.required(values.locale);
  }

  if (Validation.required(values.username)) {
    errors.username = Validation.required(values.username);
  }

  // if (Validation.required(values.email)) {
  //   errors.email = Validation.required(values.email);
  // } else if (Validation.email(values.email)) {
  //   errors.email = Validation.email(values.email);
  // }

  if (has(values, "email") && Validation.email(values.email)) {
    errors.email = Validation.email(values.email);
  }

  if (!values.himself_password && props.chosen[0] === "new") {
    if (Validation.required(values.password)) {
      errors.password = Validation.required(values.password);
    }
  }

  if (Validation.passwordsMatch(null, { password: values.password, repeatPassword: values.repeatPassword })) {
    errors.repeatPassword = Validation.passwordsMatch(null, {
      password: values.password,
      repeatPassword: values.repeatPassword,
    });
  }

  return errors;
};

export const validationsFormFolder = (values: FormValuesFolder, props): FormErrors<FormValuesFolder> => {
  const errors: FormErrors<FormValuesFolder> = {};

  if (Validation.required(values.title)) {
    errors.title = Validation.required(values.title);
  }

  if (values.description && values.description?.length > 512) {
    errors.description = Validate.maxLength512(values.description);
  }

  if (values.folderGroups?.length === 0) {
    errors.folderGroups = Validation.required(values.folderGroups);
  }

  return errors;
};

export default Validate;
