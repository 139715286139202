import { createRoutine, Routine } from "redux-saga-routines";
import { Action } from "redux-actions";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet, snakeCase } from "lodash";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

export const action: Routine = createRoutine("USER_UPDATE_OTP");

export type Payload = {
  action: boolean;
  otp: string;
};

const updateOTP = ({ action, otp }: Payload) => {
  return http({
    route: `user/otp/${action ? "enable" : "disable"}`,
    method: "PUT",
    payload: {
      otp,
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(updateOTP, payload);

    if (response.otp.enabled) {
      toast.success(i18n.t("toast_enabled_otp_success"));
    } else {
      toast.success(i18n.t("toast_disabled_otp_success"));
    }

    yield put(action.success(response));
  } catch (error) {
    const errorMessage = lodashGet(error, "response.data.detail.messages[0]", undefined);

    if (errorMessage) {
      toast.error(i18n.t(`toast_enabled_otp_error_${snakeCase(errorMessage)}`));
      yield put(action.failure());
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
