import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { get, has, snakeCase } from "lodash";
import { ACTION, FORM_USER_MANAGEMENT, getDataFromLS, http, LOCAL_STORAGE, ROLE_TYPE } from "core";
import { stopSubmit } from "redux-form";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

import { folderGetAll, getProfile, getConfig } from "store/actions";

export type FormValues = {
  username: string;
  groups: string[];
  locale: string;
  appeal?: string;
  lastName?: string;
  firstName?: string;
  roles: ROLE_TYPE;
  email?: string;
  azureId?: string;
};

export type Payload = {
  id?: string;
  action: ACTION;
  payload: FormValues;
};

export const action: Routine = createRoutine("USER_MANAGEMENT");

const management = ({ id, action, payload }: Payload) => {
  return http({
    route: action === ACTION.UPDATE ? `manager/user/${id}` : "manager/user",
    method: action === ACTION.UPDATE ? "PUT" : "POST",
    payload,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(management, payload);
    const authUser = getDataFromLS(LOCAL_STORAGE.USER);

    // if the user changes own profile language for non-application language, then update the application language

    toast.success(i18n.t(`toast_user_success_${payload.action}`));

    yield put(action.success({ response, action: payload.action }));

    if (payload.id && payload.id === authUser.userId) {
      if (payload.payload.locale !== i18n.language) {
        i18n.changeLanguage(payload.payload.locale);
      }

      yield put(folderGetAll.trigger());
      yield put(getProfile.trigger());
    }
    yield put(getConfig.trigger());
  } catch (error) {
    if (has(error, "response.data.detail.messages")) {
      yield put(stopSubmit(FORM_USER_MANAGEMENT, { _error: get(error, "response.data.detail.messages[0]") }));
    }
    if (has(error, "response.data.status")) {
      toast.error(i18n.t("settings_access_control_denied"));
    }
    if (has(error, "response.data.detail.email")) {
      toast.error(i18n.t(`toast_${snakeCase(get(error, "response.data.detail.email[0]"))}`));
    }
    yield put(action.failure(get(error, "response.data.detail.messages", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
