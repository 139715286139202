/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */

import React, { FC, useMemo, useEffect, ChangeEvent, useState, useRef } from "react";
import { Button, Icon } from "ss-ui";
import { Field, InjectedFormProps, reduxForm, WrappedFieldArrayProps, formValueSelector } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FormInput, FormSelect, cnField } from "components/Form";

import { ImageCropper } from "components/Generic";

import { validationsFormLocalUser } from "core/helpers/validation";
import {
  FORM_LOCAL_USER_MANAGEMENT,
  getUserFromListByID,
  ILanguages,
  optionsRole,
  optionsRoleWithAdmin,
  ROLE_TYPE,
  translation,
  typeUser,
  preFirstName,
  localUserForm,
  ACTION,
  getSizeImage,
  Validation,
} from "core";

import { FormValues, Payload as PayloadUsersManagementLocal } from "store/routines/users/manageLocal";
import { Payload as PayloadUsersControlBlock } from "store/routines/users/controlBlock";
import { Payload as PayloadUsersGetAvatar } from "store/routines/users/getAvatar";
import { Payload as PayloadUsersUploadAvatar } from "store/routines/users/uploadAvatar";
import { RootState } from "store/reducers";
import { usersManagementLocal, usersControlBlock, usersUploadAvatar, usersGetAvatar } from "store/actions";

import { cnSettings } from "./Settings";

type Props = {
  chosen: string[];
  currentUserRole?: ROLE_TYPE;
  formData: any;
  list: typeUser[];
  setActiveTab: (index: number) => void;
  username?: string;
  errors: any;
  usersControlBlock: (payload: PayloadUsersControlBlock) => void;
  usersGetAvatar: (payload: PayloadUsersGetAvatar) => void;
  usersManagementLocal: (payload: PayloadUsersManagementLocal) => void;
  usersUploadAvatar: (payload: PayloadUsersUploadAvatar) => void;
} & InjectedFormProps &
  WrappedFieldArrayProps<FormValues>;

const UserLocal: FC<Props> = ({
  change,
  chosen,
  currentUserRole,
  formData,
  handleSubmit,
  list,
  errors,
  setActiveTab,
  username,
  usersControlBlock,
  usersGetAvatar,
  usersManagementLocal,
  usersUploadAvatar,
}) => {
  const { t } = useTranslation();

  const [imageSrc, setImageSrc] = useState<any | null>(null);

  const user: typeUser | undefined = useMemo(() => getUserFromListByID(list, chosen[0]), [list, chosen]);

  const currentLang = localStorage.getItem("i18nextLng");

  const isNew: boolean = useMemo(() => chosen[0] === "new", [chosen]);
  const isCurrentManager: boolean = useMemo(() => currentUserRole === ROLE_TYPE.MANAGER, [currentUserRole]);
  const isAdmin: boolean = useMemo(() => user && user.roles === ROLE_TYPE.ADMIN, [user]);
  const isCurrent: boolean = useMemo(() => user && user.username === username, [user, username]);

  const [imageType, setImageType] = useState<string>("image/png");

  const logoFileInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    change("appeal", isNew ? formData.appeal || preFirstName[0] : user.appeal);
    change("firstName", isNew ? formData.firstName : user.firstName);
    change("lastName", isNew ? formData.lastName : user.lastName);
    change("locale", isNew ? formData.locale || currentLang : user.locale);
    change("roles", isNew ? optionsRole[0] : isAdmin ? optionsRoleWithAdmin[2] : user.roles);
    change("username", isNew ? formData.username : user.username);
    change("email", isNew ? formData.email : user.email);
  }, [chosen]);

  useEffect(() => {
    if (user && user.id && !user.avatarFile) {
      usersGetAvatar({ id: user.id });
    }
  }, [user]);

  const handleForm = (values, dispatch, props) => {
    /**
     * if exist error in another forms, open it
     */
    if (Object.keys(props.syncErrors).length > 0) {
      setActiveTab(2);
    } else {
      usersManagementLocal({
        id: isNew ? undefined : user.id,
        action: isNew ? ACTION.CREATE : ACTION.UPDATE,
        payload: localUserForm(values, user),
      });
    }
  };

  const handleUnblock = () => {
    usersControlBlock({ id: user.id, action: "enable" });
  };

  const handleUploadAvatar = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize: number = 5;
    const reader = new FileReader();

    if (files && files[0]) {
      if (files[0].size / 1000000 > maxFileSize) {
        toast.error(t("validate_avatar_size_limit_mb", { size: maxFileSize }));
      } else if (!["image/jpeg", "image/png", "image/jpg"].includes(files[0].type)) {
        toast.error(t("validate_avatar_format"));
      } else {
        setImageType(files[0].type);
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          const imgUrl = reader.result;
          setImageSrc(imgUrl);
        };
      }
      resetRefs();
    }
  };

  const closeCroppingModal = () => {
    setImageSrc(null);
  };

  const excludeCapitalLetters = e => {
    e.target.value = e.target.value.replace(/\s/g, "").toLowerCase();
  };

  const resetRefs = () => {
    if (logoFileInput.current) {
      logoFileInput.current.value = "";
    }
  };

  const onChangeImage = (value: string) => {
    if (getSizeImage(value) > 500) {
      toast.error(t("validate_avatar_size_limit_kb", { size: "500" }));
      setImageSrc(null);
    } else {
      usersUploadAvatar({
        id: user.id,
        userAvatar: value,
      });
    }
  };

  return (
    <form id="submit-users-form" onSubmit={handleSubmit(handleForm)} className={cnSettings()}>
      {/* <Icon name="icon-warning" fill="#E3000B" /> */}
      {user?.blocked && (
        <div className={cnSettings("Blocked")}>
          <p>
            <Icon name="icon-warning" fill="#E3000B" /> <strong>{t("user_infobox_block-user")}</strong>
          </p>
          <Button type="button" theme="danger-outline" onClick={handleUnblock}>
            {t("user_infobox_button_unblock")}
          </Button>
        </div>
      )}

      {!isNew && user && (
        <>
          <label className={cnSettings("AvatarLabel")} htmlFor="input_upload_user_avatar">
            {t("user_infobox_avatar_label")}
          </label>
          <div className={cnSettings("Avatar")}>
            <div className="image">
              <div className={`${cnField()} ${cnField("File")}`} id="input_file_avatar_container">
                <div className={cnField("FileUploadField")}>
                  <Icon name="icon-upload" width={16} />
                  <input
                    type="file"
                    ref={logoFileInput}
                    onChange={e => handleUploadAvatar(e)}
                    id="input_file_avatar"
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
              {user.avatarFile ? (
                <img src={user.avatarFile} alt={user.username} className={cnSettings("UserAvatar")} />
              ) : (
                <Icon name="icon-user-circle" width={114} height={114} />
              )}
            </div>
            <p>{t("user_infobox_avatar_helper")}</p>
          </div>
        </>
      )}

      <div className={cnSettings("FirstName")}>
        <Field
          component={FormSelect}
          className={cnSettings("FirstName-Select")}
          options={preFirstName}
          name="appeal"
          defaultSelect={preFirstName[0]}
        />
        <Field component={FormInput} label={t("form_input-first_name")} name="firstName" />
      </div>

      <Field component={FormInput} label={t("form_input-last_name")} name="lastName" />

      <Field
        component={FormInput}
        label={t("login_user_name")}
        name="username"
        disabled={!isNew}
        onInput={excludeCapitalLetters}
        validate={[Validation.emoji]}
      />

      <Field
        component={FormInput}
        label={t("form_input-Email")}
        name="email"
        validate={[Validation.emoji, Validation.email, Validation.required]}
      />

      <Field
        component={FormSelect}
        label={t("form_input-Role")}
        name="roles"
        disabled={isCurrent || (isAdmin && isCurrentManager)}
        placeholder={t("form_input_select-Role")}
        options={isCurrentManager ? optionsRole : optionsRoleWithAdmin}
      />

      <Field
        component={FormSelect}
        label={t("form_input-Language")}
        name="locale"
        placeholder={t("form_input_select-Language")}
        options={translation.languages.map(({ code, title }: ILanguages) => ({ value: code, label: title }))}
        template={(label: string, value: string): JSX.Element => (
          <div className="ManageKey-Option">
            <Icon name={`colored/icon-lang-${value || "en"}`} width={16} />
            &nbsp;
            {label}
          </div>
        )}
      />
      {imageSrc && (
        <div className={cnSettings("Crop")}>
          <ImageCropper
            image={imageSrc}
            cropArea={true}
            cropMaxSize={{ height: 150, width: 150 }}
            aspect={1 / 1}
            handleClose={closeCroppingModal}
            onChangeImage={onChangeImage}
            setImageValue={setImageSrc}
            type={imageType}
          />
        </div>
      )}
    </form>
  );
};

const selector = formValueSelector(FORM_LOCAL_USER_MANAGEMENT);

const mapStateToProps = (state: RootState) => ({
  chosen: state.users.chosen,
  currentUserRole: state.user.role,
  list: state.users.list,
  errors: state.users.error,
  username: state.user.username,
  formData: selector(state, "firstName", "lastName", "username", "email", "locale", "roles", "password", "himself_password"),
});

const mapDispatchToProps = { usersManagementLocal, usersControlBlock, usersUploadAvatar, usersGetAvatar };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    form: FORM_LOCAL_USER_MANAGEMENT,
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate: validationsFormLocalUser,
  }),
)(UserLocal);
