import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { Button, Icon } from "ss-ui";
import { Field, Form, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as msal from "@azure/msal-browser";
import { FormInput } from "components/Form";

import {
  FORM_LOGIN,
  LOGIN_TYPE,
  USER_STORAGE,
  Routes,
  typeAuthorizationSupports,
  IMicrosoftAuth,
  excludeCapitalLetters,
  removeSpaces,
  updateOnInputString,
  typeAuthenticateOptions,
  getPortalLink,
  Validation,
} from "core";

import { PropsWithLoading, withLoading } from "hoc";

import { FormValues, Payload as PayloadLogin } from "store/routines/user/login";
import { RootState } from "store/reducers";
import { login, loginAzure, loginYubikey } from "store/actions";
import { Payload as PayloadLoginAzure } from "store/routines/user/loginAzure";
import { Payload as PayloadLoginYubikey } from "store/routines/user/loginYubikey";

import { useLogin } from "core/hook/yubikey";
import { Timer } from "components/Generic";
import { cnMessages } from "components/Messages";
import { cnLogin } from "../Login";

const RESEND_COUNTDOWN_TIME = 60;

enum AuthType {
  CREDENTIALS = "credentials",
  OTP = "otp",
  VERIFICATION = "verification",
}

type AuthState = {
  type: AuthType;
  showHelp: boolean;
};

type Props = PropsWithLoading &
  InjectedFormProps<FormValues> &
  RouteComponentProps<undefined> & {
    authenticateOptions?: typeAuthenticateOptions;
    authorizationSupports: typeAuthorizationSupports;
    formData: FormValues;
    formErrors: Record<string, string>;
    isLoading: boolean;
    login: (payload: PayloadLogin) => void;
    loginAzure: (payload: PayloadLoginAzure) => void;
    loginYubikey: (payload: PayloadLoginYubikey) => void;
    microsoftAuth: IMicrosoftAuth;
    setLoginType: (type: LOGIN_TYPE) => void;
    submitFailed: boolean;
    stateError: string;
    userStorage: USER_STORAGE;
  };

const LoginForm: FC<Props> = ({
  authenticateOptions,
  authorizationSupports,
  formData,
  formErrors,
  change,
  handleSubmit,
  isLoading,
  login,
  loginAzure,
  loginYubikey,
  microsoftAuth,
  setIsLoading,
  setLoginType,
  submitFailed,
  stateError,
  userStorage,
  untouch,
}) => {
  const { t } = useTranslation();
  const handlerLoginYubikey = useLogin({});

  const passwordRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isExpire, setIsExpire] = useState<boolean>(false);
  const [leftTime, setLeftTime] = useState<number>(RESEND_COUNTDOWN_TIME);
  const [error, setError] = useState<string | null>(null);

  const [authState, setAuthState] = useState<AuthState>({
    type: AuthType.CREDENTIALS,
    showHelp: false,
  });

  const hasOTPError = useMemo(() => stateError === "error_otp_required", [stateError]);
  const hasVerificationError = useMemo(() => stateError === "error_verification_code_required", [stateError]);
  const isAdditionalCodeRequired = useMemo(() => authState.type !== AuthType.CREDENTIALS, [authState]);

  useEffect(() => {
    setError(stateError);

    if (error === "error_invalid_credentials" && authState.type === AuthType.CREDENTIALS) {
      change("password", null);
      passwordRef.current?.focus();
    }

    if (hasOTPError) {
      setAuthState(prev => ({ ...prev, type: AuthType.OTP }));
    }
    if (hasVerificationError) {
      setAuthState(prev => ({ ...prev, type: AuthType.VERIFICATION }));
    }
  }, [stateError]);

  useEffect(() => {
    if (authState.type === AuthType.OTP) {
      change("otp", null);
      untouch("otp");
    }

    if (authState.type === AuthType.VERIFICATION) {
      change("verification_code", null);
      untouch("verification_code");
    }

    if (isAdditionalCodeRequired) {
      inputRef.current?.focus();
    }
  }, [authState.type, error]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (authenticateOptions) {
      handlerLoginYubikey(authenticateOptions)
        .then(response => {
          loginYubikey(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [authenticateOptions]);

  useEffect(() => {
    if (leftTime === 0) {
      setIsExpire(false);
      setLeftTime(RESEND_COUNTDOWN_TIME);
    }
  }, [leftTime]);

  const handleAzureLogin = () => {
    const msalConfig = {
      auth: {
        clientId: microsoftAuth.client_id || "",
        authority: `https://login.microsoftonline.com/${microsoftAuth.tenant_id || ""}`,
        redirectUri: `${window.location.origin}${Routes.Login}`,
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };

    const msalInstance = new msal.PublicClientApplication(msalConfig);

    setIsLoading(true);

    msalInstance.loginPopup({ scopes: [] })
      .then((tokenResponse: any) => {
        loginAzure(tokenResponse);
      })
      .catch((error: any) => {
        if (error.errorCode === "user_cancelled") {
          // console.log("User closed the login popup"); need added error if need
        } else {
          console.error(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBackToCredentials = () => {
    setError(null);
    setAuthState({ type: AuthType.CREDENTIALS, showHelp: false });
    setIsExpire(false);

    change("otp", null);
    change("verification_code", null);
    change("password", null);

    passwordRef.current?.focus();
  };

  const handleLogin = (data: FormValues) => {
    setError(null);

    login({
      values: {
        otp: data.otp,
        password: data.password,
        save_account: false,
        username: data.username,
        verification_code: data.verification_code,
        ignore_verification_code: data.ignore_verification_code,
      },
    });
  };

  const handleResendCode = () => {
    setIsExpire(true);
    handleLogin({ ...formData, verification_code: undefined });
  };

  return (
    <Form className={cnLogin("Form")} onSubmit={handleSubmit(handleLogin)} id="page_login_form">
      {isAdditionalCodeRequired ? (
        <div className={cnLogin("FormHeader")}>
          <Icon className={cnLogin("FormHeader-Icon-Pointer")} name="icon-arrow-left" onClick={handleBackToCredentials} />
          <p>{formData?.username}</p>
        </div>
      ) : (
        <div className={cnLogin("FormHeader")}>
          <Icon name="icon-user" />
          <p>{t("login_login")}</p>
        </div>
      )}
      {error && !hasOTPError && (
        <div className={cnLogin("Error", { Warning: hasVerificationError })}>
          {!hasVerificationError && <Icon fill="#E3000B" name="icon-warning" />}
          {error === "error_account_is_blocked" ? (
            <div>
              <Trans i18nKey="error_account_is_blocked">
                <a href={`${getPortalLink(window.location.host)}/login`} target="_blank">
                  Customer Portal
                </a>
              </Trans>
            </div>
          ) : (
            <div>
              <Trans i18nKey={error}>
                <div className={cnLogin("Error-line-break")} />
              </Trans>
            </div>
          )}
        </div>
      )}
      <div className={cnLogin("FieldsContainer")}>
        <div className={cnLogin("FieldGroup", { Hidden: isAdditionalCodeRequired })} id="field_group_login_username_password">
          <Field
            className={cnLogin("FieldGroupInput", { "has-error": !!error && !isAdditionalCodeRequired })}
            component={FormInput}
            name="username"
            placeholder={t(`${userStorage === USER_STORAGE.LOCAL ? "login_user_name_email" : "login_user_name"}`)}
            type="input"
            onInput={(e: any) => updateOnInputString(e, excludeCapitalLetters)}
            validate={[Validation.required]}
          />
          <Field
            className={cnLogin("FieldGroupInput", { "has-error": !!error && !isAdditionalCodeRequired })}
            component={FormInput}
            isShown
            name="password"
            placeholder={t("login_user_pass")}
            type="password"
            onInput={(e: any) => updateOnInputString(e, removeSpaces)}
            inputRef={passwordRef}
            validate={[Validation.required]}
          />
          {submitFailed && (formErrors.username || formErrors.password) && (
            <div className={`${cnMessages()} ${cnMessages("Error")}`}>{t("validate_required")}</div>
          )}
        </div>
        <div className={cnLogin("Otp", { Hidden: !isAdditionalCodeRequired })}>
          {isAdditionalCodeRequired && (
            <>
              <Field
                className={cnLogin("FieldGroupInputFlex")}
                component={FormInput}
                isShown
                placeholder={authState.type === AuthType.OTP ? t("login_otp_placeholder") : t("login_verification_placeholder")}
                label={authState.type === AuthType.OTP ? t("login_one_pass") : t("login_verification")}
                linkHandle={() => setAuthState(prev => ({ ...prev, showHelp: !prev.showHelp }))}
                linkText={t("login_faq")}
                name={authState.type === AuthType.OTP ? "otp" : "verification_code"}
                type="input"
                validate={[Validation.required]}
                inputRef={inputRef}
              />
              {authState.showHelp && (
                <ClickAwayListener onClickAway={() => setAuthState(prev => ({ ...prev, showHelp: false }))}>
                  <div className={cnLogin("Otp-Open")}>
                    {authState.type === AuthType.OTP ? (
                      <p>
                        {t("login_otp")}
                        (&nbsp;
                        <a
                          href="https://apps.apple.com/us/app/accesssecurium/id1072367945"
                          rel="noopener noreferrer"
                          target="_blank"
                          id="link_download_ios_app"
                        >
                          iOS
                        </a>
                        &nbsp;|&nbsp;
                        <a
                          href="https://play.google.com/store/apps/details?id=ch.alpeinsoft.accesssecurium"
                          id="link_download_android_app"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          GooglePlay
                        </a>
                        &nbsp;).
                      </p>
                    ) : (
                      <p> {t("login_verification_code")}</p>
                    )}
                  </div>
                </ClickAwayListener>
              )}
            </>
          )}
        </div>
      </div>

      <div className={cnLogin("ButtonContainer")} id="page_login_button_container">
        {authState.type === AuthType.VERIFICATION && (
          <>
            {isExpire ? (
              <p>
                <Trans i18nKey="error_verification_code_resend">
                  <Timer seconds={leftTime} setSeconds={setLeftTime} />
                </Trans>
              </p>
            ) : (
              <Button id="button_login_resend_verify" type="button" theme="primary-without-border" width="100%" onClick={handleResendCode}>
                {t("login_resend_verify")}
              </Button>
            )}
            <Button
              id="button_login_verify_later"
              theme="primary-outline"
              type="button"
              width="100%"
              onClick={() => handleLogin({ ...formData, verification_code: undefined, ignore_verification_code: true })}
            >
              {t("login_verify_later")}
            </Button>
          </>
        )}
        <Button id="button_login_ps" type="submit" width="100%">
          {t("login_login")}
        </Button>
        {authorizationSupports.microsoft && (
          <>
            <div className={cnLogin("ActionDelimiter")}>
              <div className="line">&nbsp;</div>
              <div className="or">{t("log_in_another")}</div>
              <div className="line">&nbsp;</div>
            </div>
            <Button
              type="button"
              onClick={() => handleAzureLogin()}
              id="btn_log_in_with_azure"
              theme="secondary-outline"
              width="100%"
              icon="colored/icon-azure"
            >
              {t("log_in_with_azure")}
            </Button>
          </>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (state: RootState) => ({
  formData: getFormValues(FORM_LOGIN)(state),
  formErrors: getFormSyncErrors(FORM_LOGIN)(state),
  submitFailed: state.form?.FORM_LOGIN?.submitFailed || false,
  isLoading: state.user.isLoading,
  stateError: state.user.error,
  authenticateOptions: state.user.authenticateOptions,
  authorizationSupports: state.settings.authorizationSupports,
  microsoftAuth: state.settings.microsoftAuth,
  userStorage: state.settings.userStorage,
});

export default compose<Props, Partial<Props>>(
  withLoading,
  withRouter,
  connect(mapStateToProps, { loginAzure, login, loginYubikey }),
  reduxForm<FormValues>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_LOGIN,
    touchOnBlur: false,
  }),
)(LoginForm);
