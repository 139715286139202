/* eslint-disable no-useless-return */
import React, { ChangeEvent, FC, useState, useContext, useEffect } from "react";
import { Field } from "redux-form";
import { Icon } from "ss-ui";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { FormInput } from "components/Form";
import { toast } from "react-toastify";

import { Validation, FIELD_TYPE, apiCallCheckField, getFieldValue } from "core";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { RootState } from "store/reducers";

import { FieldContext } from "./Types";

type Props = {
  pwnedOff: boolean;
  loginValid: (value: number) => void;
};

const fieldType = FIELD_TYPE.LOGIN;

const FieldLogin: FC<Props> = ({ pwnedOff, loginValid }) => {
  const { t } = useTranslation();

  const [pwnedStatus, setPwnedStatus] = useState<number>(4);
  const [isLoading, setLoading] = useState<boolean>(false);

  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
      pwnedCheck(context.formData[fieldType], true);
    } else {
      getFieldValue(fieldType, context.key).then(value => {
        if (value) {
          context.handleChange(fieldType, value);
          pwnedCheck(value, true);
        }
      });
    }
  }, [context.key]);

  const pwnedCheck = async (value: string, firstCall: boolean) => {

    if (pwnedStatus !== 0 && !firstCall) {
      return;
    }

    if (!value) {
      return;
    }

    if (pwnedOff || !Validation.validateEmail(value)) {
      return;
    }

    setPwnedStatus(4);
    setLoading(true);

    const status = await apiCallCheckField({
      field: fieldType,
      value,
    });

    setTimeout(() => {
      changeStatusByPwnedCheckResponse(status);
    }, 4000);

  };

  const changeStatusByPwnedCheckResponse = (status: boolean) => {
    if (status) {
      changeCheckedStatus(1, 1);
    } else {
      changeCheckedStatus(2, 2);
      toast.warning(t("toast_pwned_warning_login"));
    }
    setLoading(false);
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {

    if (!value) {
      changeCheckedStatus();
      return;
    }

    if (pwnedOff || !Validation.validateEmail(value)) {
      changeCheckedStatus();
      return;
    }

    changeCheckedStatus(0,0);
  };

  const changeCheckedStatus = (fieldStatus: number = 4, formStatus: number = 4) => {
    setPwnedStatus(fieldStatus);
    loginValid(formStatus);
  }

  return (
    <div
      className={cnManageKey("CheckInput",
        {
          checkedLoginWait: pwnedStatus === 0,
          checkedLoginSuccess: pwnedStatus === 1,
          checkedLoginError: pwnedStatus === 2
        })}
    >
      <input className="fakePassport" type="text" name="user" />
      <Field
        component={FormInput}
        label={t("form_input-Login")}
        name={fieldType}
        className="Required"
        disabled={isLoading}
        onBlur={(el: any) => pwnedCheck(el.target.value, false)}
        placeholder={t("form_input-Enter-Login")}
        onChange={handleChange}
        validate={[Validation.required, Validation.maxLength255, Validation.emoji]}
      />

      {isLoading && <div className={cnManageKey("CheckInput-Loading")}>{t("key-field-check")}</div>}

      {pwnedStatus === 1 && (
        <div className={cnManageKey("CheckInput-Checked")}>
          <Icon name="icon-checked" fill="#49B109" />
          {t("key-field-check-success")}
        </div>
      )}
      {pwnedStatus === 2 && (
        <div className={cnManageKey("CheckInput-Warning")}>
          <Icon name="icon-info-circle" fill="#F0A600" width={15} height={15} />
          {t("key-field-check-notValid")}
        </div>
      )}
      {pwnedStatus === 0 && !pwnedOff && (
        <div className={cnManageKey("CheckInput-Waiting")}>
          {t("key-field-check-wait")} &#8594;
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ settings: { hibpwnedEnabled, pwned } }: RootState) => ({
  pwnedOff: !hibpwnedEnabled && !pwned,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FieldLogin);
