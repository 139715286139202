import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { get as lodashGet, has, mapValues, snakeCase } from "lodash";
import { stopSubmit } from "redux-form";
import { ACTION, http, typeGroupFolder, FORM_GROUP_MANAGEMENT } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import i18n from "locales/i18n";
import { folderGetAll, favoritesGet } from "store/actions";

export type FormValues = {
  name: string;
  folders: typeGroupFolder[];
  users: (string | undefined)[];
  azureId?: string,
};

export type Payload = {
  id?: string;
  action: ACTION;
  payload: FormValues;
};

export const action: Routine = createRoutine("GROUPS_MANAGEMENT");

const management = ({ id, action, payload }: Payload) => {
  const { name, folders, users, azureId } = payload;
  return http({
    route: action === ACTION.UPDATE ? `manager/group/${id}` : "manager/group",
    method: action === ACTION.UPDATE ? "PUT" : "POST",
    payload: action === ACTION.UPDATE ? {
      ...payload,
      folders: folders.length > 0 ? folders : [],
    } : { name, users, azureId },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(management, payload);

    toast.success(i18n.t(`toast_group_success_${payload.action === ACTION.CREATE ? "create" : "update"}`));

    yield put(folderGetAll.trigger());
    yield put(favoritesGet.trigger());

    yield put(action.success({ response, action: payload.action }));
  } catch (error) {
    const errorMessage = lodashGet(error, "response.data.detail.messages[0]", undefined);

    if (has(error, "response.data.detail")) {
      yield put(
        stopSubmit(
          FORM_GROUP_MANAGEMENT,
          mapValues(lodashGet(error, "response.data.detail"), e => i18n.t(`toast_folder_error_${snakeCase(e[0])}`)),
        ),
      );

      if (errorMessage) {
        toast.error(i18n.t(`toast_folder_error_${snakeCase(errorMessage)}`));
      } else if (has(error, "response.data.status")) {
        toast.error(i18n.t("settings_access_control_denied"));
      } else {
        yield put(action.failure(lodashGet(error, "message", undefined)));
      }
    } else {
      yield put(action.failure(lodashGet(error, "message", undefined)));
    }
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
