import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { LOCAL_STORAGE } from "core/services/localStorage";
import { translationType } from "core";

const localeEnv = process.env.REACT_APP_LOCALE ? process.env.REACT_APP_LOCALE.toLowerCase() : "en";
const locale = ["en", "de"].includes(localeEnv) ? localeEnv : "en";
export const defaultLanguage: translationType = ["en", "de"].includes(locale) ? (locale as translationType) : "en";

export const getUserLocale = () => {
  const localStorageLanguage: string | null = localStorage.getItem(LOCAL_STORAGE.LANGUAGE);
  const userBrowserLanguage: translationType = navigator.language.split("-")[0] as translationType;
  const languagesSupport: translationType[] = ["en", "de"];

  if (localStorageLanguage) return localStorageLanguage;

  if (languagesSupport.indexOf(userBrowserLanguage) >= 0) {
    return userBrowserLanguage;
  }

  return defaultLanguage;
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getUserLocale(),
    debug: process.env.NODE_ENV === "development",
    fallbackLng: defaultLanguage,
    saveMissing: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translations: {
          ...require("./en/translation.json"),
          ...require("./en/activity-log.json"),
          ...require("./en/customize.json"),
          ...require("./en/navigation.json"),
          ...require("./en/settings-yubikey.json"),
          ...require("./en/manage-key.json"),
          ...require("./en/login-master-key.json"),
          ...require("./en/modal-check-password.json"),
          ...require("./en/modal-migration.json"),
          ...require("./en/backup-master-key.json"),
          ...require("./en/modal-save-mnemonic.json"),
          ...require("./en/change-access-key.json"),
        },
      },
      de: {
        translations: {
          ...require("./de/translation.json"),
          ...require("./de/activity-log.json"),
          ...require("./de/customize.json"),
          ...require("./de/navigation.json"),
          ...require("./de/settings-yubikey.json"),
          ...require("./de/manage-key.json"),
          ...require("./de/login-master-key.json"),
          ...require("./de/modal-check-password.json"),
          ...require("./en/modal-migration.json"),
          ...require("./en/backup-master-key.json"),
          ...require("./en/modal-save-mnemonic.json"),
          ...require("./de/change-access-key.json"),
        },
      },
    },
    parseMissingKeyHandler: () => {
      return i18n.t("default_value");
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
