/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, RefObject, useMemo, useState } from "react";
import { WrappedFieldProps } from "redux-form";
import { Icon, Link } from "ss-ui";

import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";

type Props = {
  className?: string;
  help?: string;
  isShown: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  label: string;
  linkHandle?: () => void;
  linkText?: string;
  restrictCopyPaste: boolean;
  type: string;
  isAutoCompleteOn?: boolean;
} & WrappedFieldProps;

const passwordNames: string[] = [
  "oldPassword",
  "repeatPassword",
  "card_pin",
  "password",
  "password.first",
  "password.second",
  "vpn.password",
  "otp",
  "mailerPassword",
  "verification_code",
];

const isPassword = (inputName: string) => passwordNames.indexOf(inputName) >= 0;
const getFieldType = (isPassword: boolean) => (isPassword ? "password" : "text");
const isAutoComplete = (isPassword: boolean) => (isPassword ? "off" : "on");

const FormInput: FC<Props> = ({
  className = "",
  help = undefined,
  input,
  inputRef,
  isShown = false,
  label,
  linkHandle,
  linkText,
  meta: { touched, error },
  restrictCopyPaste = false,
  type,
  isAutoCompleteOn = true,
  ...rest
}) => {
  const [shown, setShown] = useState<boolean>(false);

  const inputType = useMemo(() => (isShown && shown ? "input" : getFieldType(isPassword(input.name))), [shown, input.name]);

  const isError = touched && Boolean(error);

  const handleCopyPast = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (restrictCopyPaste) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={`${cnField()} ${cnField("Input", { hasError: isError })} ${input.value === "" ? className : className} ${isShown ? "PassInput" : ""}`}
      id={`input_${inputType}_${input.name}_container`}
    >
      {label && (
        <span className="Label" id={`input_${inputType}_${input.name}_label`}>
          {label}{" "}
          {linkText && (
            <Link size={14} id="button_input_link" onClick={linkHandle}>
              {linkText}
            </Link>
          )}
          {help && (
            <span data-tooltip={help} className="help tooltip tooltip-right tooltip-sealing">
              <Icon name="icon-info-circle" width={15} height={15} />
            </span>
          )}
        </span>
      )}
      <div className={`${cnField("InputWrapper")} ${isShown ? cnField("InputWrapper-Password") : null} ${isShown && !shown ? "input" : getFieldType(isPassword(input.name))}`}>
        <input
          autoComplete={isAutoComplete(isPassword(input.name) || !isAutoCompleteOn)}
          id={`input_${inputType}_${input.name}`}
          onCopy={handleCopyPast}
          onPaste={handleCopyPast}
          ref={inputRef}
          type={inputType}
          {...input}
          {...rest}
        />
        {isShown && (
          <button
            className={cnField("InputWrapperButton")}
            type="button"
            onClick={() => setShown(!shown)}
            id={`input_${inputType}_${input.name}_show_password`}
          >
            <Icon name="icon-eye" width={20} height={14} />
          </button>
        )}
      </div>
      {isError && (
        <span id={`input_${inputType}_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};

export default FormInput;
