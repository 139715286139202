import { http, getDataFromLS, LOCAL_STORAGE } from "core";
import { get as lodashGet, snakeCase } from "lodash";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { folderGetAll, setGlobalSearch } from "store/actions";
import { toast } from "react-toastify";
import i18n from "locales/i18n";

export type Payload = {
  keyId?: string;
  folders?: string[];
};

export const action: Routine = createRoutine("DELETE_KEY_SHARING");

const deleteKeySharing = ({ keyId, folders }: Payload) => {
  return http({
    method: "DELETE",
    payload: { folders },
    route: `key/${keyId}/share`,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(deleteKeySharing, payload);

    yield put(action.success(response));

    const lastSearch = getDataFromLS(LOCAL_STORAGE.LAST_SEARCH_VALUE);
    if (window.location.pathname.includes("search") && lastSearch) {
      yield put(setGlobalSearch.trigger(lastSearch));
    }
    yield put(folderGetAll.trigger());
  } catch (error) {
    const errorDetail = lodashGet(error, "response.data.detail", undefined);

    if (errorDetail) {
      yield put(action.failure(errorDetail));
      toast.error(i18n.t(`toast_key_error_${snakeCase(lodashGet(errorDetail, "messages[0]"))}`));

      yield put(folderGetAll.trigger());
    } else {
      yield put(action.failure(lodashGet(error, "messages", undefined)));
      toast.error(i18n.t("toast_key_error_access_denied"));
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
