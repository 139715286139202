import React, { FC, useEffect, useRef, ChangeEvent, useState } from "react";
import { Button, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { compose } from "recompose";
import { toast } from "react-toastify";
import { Field, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";

import { SettingsNavigation, changeMainColor, FORM_CUSTOMERS, Validation, getSizeImage } from "core";

import { ContentWrapper, Content, ContentHeader, Aside, cnAsideContent } from "components/Layout";
import { FormInput } from "components/Form";
import { Header } from "components/Navigation";
import { LoadingContainer, ImageCropper } from "components/Generic";

import { Payload as payloadSetCustomize } from "store/routines/settings/setCustomize";
import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";
import { setCustomize, setHeaderIcon, setHeaderTitle } from "store/actions";

import CustomizeFooterMail from "./CustomizeFooterMail";

export const cnCustomize = cn("Customize");

export const defaultCustomValue = {
  background: "reset",
  logo: "reset",
  companyName: "2016-%year% ALPEIN Software SWISS AG",
  companyAddress: "ALPEIN Software SWISS AG | Stein am Rhein, Switzerland",
  primaryColor: "#1A90BB",
  footerEn: "Your Pass Securium Team",
  footerDe: "Ihr Pass Securium Team",
};

type Props = {
  customize: SettingsCustomizeState;
  setCustomize: (payload: payloadSetCustomize) => void;
  setHeaderIcon: (payload: string) => void;
  setHeaderTitle: (payload: string) => void;
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

const Customize: FC<Props> = ({ customize, handleSubmit, setCustomize, setHeaderIcon, setHeaderTitle, change }: Props) => {
  const { t } = useTranslation();

  const [logoSrc, setLogoSrc] = useState<any | null>(null);
  const [bgSrc, setBgSrc] = useState<any | null>(null);
  const [mainColor, setMainColor] = useState<string>(defaultCustomValue.primaryColor);
  const [errorMaxSizeLogo, setErrorMaxSizeLogo] = useState<boolean>(false);
  const [errorMaxSizeBg, setErrorMaxSizeBg] = useState<boolean>(false);
  const [imageType, setImageType] = useState<string>("image/png");

  /**
   * Got ref for input file and mainColor
   */
  const logoFileInput = useRef<HTMLInputElement | null>(null);
  const backgroundFileInput = useRef<HTMLInputElement | null>(null);
  const colorInput = useRef<HTMLInputElement | null>(null);

  /**
   * UseEffect one time when page loaded
   */
  useEffect(() => {
    setHeaderTitle(t("nav-Customize"));
    setHeaderIcon("customize");
  }, []);

  /**
   * UseEffect update customize
   */
  useEffect(() => {
    change("companyName", customize.companyName);
    change("companyAddress", customize.companyAddress);
    if (customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  /**
   * Change color when color change, polymorphism of react-hook-form event
   */
  useEffect(() => {
    changeMainColor(mainColor);
  }, [mainColor]);

  /**
   * Send update Company info
   */
  const handleFormCompanyInfo = (data: any) => {
    setCustomize({
      companyName: data.companyName,
      companyAddress: data.companyAddress,
    });
  };

  /**
   * Reset to default Company info
   */
  const handleResetFormCompanyInfo = () => {
    setCustomize({
      companyName: defaultCustomValue.companyName,
      companyAddress: defaultCustomValue.companyAddress,
    });
  };

  /**
   * Upload images: logo or background
   */
  const handleUploadImages = ({ target: { files } }: ChangeEvent<HTMLInputElement>, actions: string) => {
    const maxFileSize: number = 2;
    const reader = new FileReader();

    if (files && files[0]) {
      if (files[0].size / 1000000 > maxFileSize) {
        toast.error(t("validate_avatar_size_limit_mb", { size: maxFileSize }));
        if (actions === "logo") {
          setErrorMaxSizeLogo(true);
        } else {
          setErrorMaxSizeBg(true);
        }
      } else if (!["image/jpeg", "image/png", "image/jpg"].includes(files[0].type)) {
        toast.error(t("validate_avatar_format"));
      } else {
        setImageType(files[0].type);
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          const imgUrl = reader.result;
          if (actions === "logo") {
            setLogoSrc(imgUrl);
          } else {
            setBgSrc(imgUrl);
          }
        };
      }
    }
    resetRefs();
  };

  const closeCroppingModal = () => {
    setLogoSrc(null);
    setBgSrc(null);
  };

  const onChangeImage = (value: string, name: string) => {
    if (getSizeImage(value) > 500 && name === "logo") {
      toast.error(t("validate_avatar_size_limit_kb", { size: "500" }));
      setErrorMaxSizeLogo(true);
      setLogoSrc(null);
    } else if (getSizeImage(value) > 2000 && name === "bg") {
      toast.error(t("validate_avatar_size_limit_mb", { size: "2" }));
      setErrorMaxSizeBg(true);
      setBgSrc(null);
    } else {
      fetch(value)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], `${name}.jpg`, { type: "image/jpg" });
          if (name === "logo") {
            setCustomize({ logo: file });
            setErrorMaxSizeLogo(false);
            setLogoSrc(null);
          } else {
            setCustomize({ background: file });
            setErrorMaxSizeBg(false);
            setBgSrc(null);
          }
        });
    }
  };

  const resetRefs = () => {
    if (logoFileInput.current) {
      logoFileInput.current.value = "";
    }
    if (backgroundFileInput.current) {
      backgroundFileInput.current.value = "";
    }
  };

  const resetLogo = () => {
    setCustomize({ logo: defaultCustomValue.logo });
  };

  const resetBackground = () => {
    setCustomize({ background: defaultCustomValue.background });
  };

  const handleResetColor = () => {
    setCustomize({ primaryColor: defaultCustomValue.primaryColor });
  };

  const handleResetAll = () => {
    setCustomize(defaultCustomValue);
  };

  return (
    <ContentWrapper id="theming_content_wrapper">
      <Content id="theming_content" className={cnCustomize()}>
        <ContentHeader id="theming_content_header">
          <Header linkList={SettingsNavigation} />
        </ContentHeader>
        <LoadingContainer isLoading={customize.isLoading}>
          <div className={cnCustomize("Sections")}>
            <div className={cnCustomize("Section")}>
              <div className={cnCustomize("Header")}>
                <span className={cnCustomize("Header-Name")}>{t("customize_header_label_company_info")}</span>
                <Button theme="danger-link" size={30} icon="icon-refresh" onClick={handleResetFormCompanyInfo}>
                  {t("customize_btn_reset_to_default")}
                </Button>
              </div>
              <form id="submit-company-form" onSubmit={handleSubmit(handleFormCompanyInfo)}>
                <div className={cnCustomize("Content")}>
                  <div className={cnCustomize("Content-Left")}>
                    <Field
                      component={FormInput}
                      label={t("customize_label_full_company_name")}
                      name="companyName"
                      placeholder={t("customize_placeholder_full_company_name")}
                      validate={[Validation.emoji, Validation.required, Validation.maxLength100]}
                    />
                    <div className={cnCustomize("Content-Help")}>{t("customize_help_company_name")}</div>
                  </div>
                  <div className={cnCustomize("Content-Right")}>
                    <Field
                      component={FormInput}
                      label={t("customize_label_company_address")}
                      name="companyAddress"
                      placeholder={t("customize_placeholder_company_address")}
                      validate={[Validation.emoji, Validation.required, Validation.maxLength100]}
                    />
                    <div className={cnCustomize("Content-Help")}>{t("customize_help_company_address")}</div>
                  </div>
                </div>
                <div className={cnCustomize("Content-Submit")}>
                  <Button form="submit-company-form" type="submit" className="mr20">
                    {t("customize_btn_save")}
                  </Button>
                </div>
              </form>
            </div>

            <div className={cnCustomize("Delimiter")} />

            <CustomizeFooterMail />

            <div className={cnCustomize("Delimiter")} />

            <div className={cnCustomize("Section")}>
              <div className={cnCustomize("Header")}>
                <span className={cnCustomize("Header-Name")}>{t("customize_header_label_company_logo")}</span>
                <Button theme="danger-link" size={30} icon="icon-refresh" onClick={resetLogo}>
                  {t("customize_btn_reset_to_default")}
                </Button>
              </div>
              <div className={cnCustomize("Content")}>
                <div className={cnCustomize("Content-Left")}>
                  <div className={cnCustomize("Content-Help")}>
                    <Trans i18nKey="customize_company_logo_help">
                      <b>1</b>
                      <b>2</b>
                    </Trans>
                  </div>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="input_logo"
                    ref={logoFileInput}
                    onChange={e => handleUploadImages(e, "logo")}
                  />
                  <Button onClick={() => logoFileInput.current && logoFileInput.current.click()} icon="icon-upload">
                    {t("customize_btn_upload")}
                  </Button>
                  {errorMaxSizeLogo && <div className="error">{t("validate_avatar_size_limit_mb", { size: 2 })}</div>}
                </div>
                <div className={cnCustomize("Content-Right")}>
                  <div className={cnCustomize("Content-Logo")}>
                    {customize.logo ? <img src={customize.logo} alt="Logo" /> : <Icon name="app/pass-logo-horizontal" />}
                  </div>
                </div>
              </div>
            </div>

            <div className={cnCustomize("Delimiter")} />

            <div className={cnCustomize("Section")}>
              <div className={cnCustomize("Header")}>
                <span className={cnCustomize("Header-Name")}>{t("customize_background_header_label")}</span>
                <Button theme="danger-link" size={30} icon="icon-refresh" onClick={resetBackground}>
                  {t("customize_btn_reset_to_default")}
                </Button>
              </div>
              <div className={cnCustomize("Content")}>
                <div className={cnCustomize("Content-Left")}>
                  <div className={cnCustomize("Content-Help")}>
                    <Trans i18nKey="customize_background_help">
                      <b>1</b>
                      <b>2</b>
                    </Trans>
                  </div>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="input_background"
                    ref={backgroundFileInput}
                    onChange={e => handleUploadImages(e, "bg")}
                  />
                  <Button onClick={() => backgroundFileInput.current && backgroundFileInput.current.click()} icon="icon-upload">
                    {t("customize_btn_upload")}
                  </Button>
                  {errorMaxSizeBg && <div className="error">{t("validate_avatar_size_limit_mb", { size: 2 })}</div>}
                </div>
                <div className={cnCustomize("Content-Right")}>
                  <div
                    className={cnCustomize("Content-Bg")}
                    style={{
                      backgroundImage: customize.backgroundLogin ? `url(${customize.backgroundLogin})` : "",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={cnCustomize("Delimiter")} />

            <div className={cnCustomize("Section")}>
              <div className={cnCustomize("Header")}>
                <span className={cnCustomize("Header-Name")}>{t("customize_color_header_label")}</span>
                <Button theme="danger-link" icon="icon-refresh" onClick={handleResetColor}>
                  {t("customize_btn_reset_to_default")}
                </Button>
              </div>

              <div className={cnCustomize("Content")}>
                <div className={cnCustomize("Content-Left")}>
                  <div className={cnCustomize("Content-Help")}>{t("customize_color_help")}</div>
                  <div className={cnCustomize("ColorPicker")}>
                    <div className={cnCustomize("ColorPicker-Input")}>
                      <label htmlFor="primary_color">{t("customize_color_label")}</label>
                      <div className={cnCustomize("ColorPicker-Color")}>
                        <input
                          type="color"
                          id="primary_color"
                          value={mainColor}
                          ref={colorInput}
                          onChange={e => setMainColor(e.target.value)}
                        />
                        <label htmlFor="primary_color">{mainColor}</label>
                      </div>
                    </div>
                    {customize.colorMain !== mainColor ? (
                      <Button onClick={() => setCustomize({ primaryColor: mainColor })}>{t("customize_btn_save")}</Button>
                    ) : null}
                  </div>
                </div>
                <div className={cnCustomize("Content-Right")}>
                  <div className={cnCustomize("Content-Help")}>&nbsp;</div>
                  <div className={cnCustomize("ColorsPreview")}>
                    <div className="primary">
                      <div className="color color-1">&nbsp;</div>
                      <div className="color color-2">&nbsp;</div>
                      <div className="color color-3">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={cnCustomize("Delimiter")} />
          </div>
        </LoadingContainer>
      </Content>
      <Aside side="right" id="theming_aside_right">
        <div className={cnAsideContent()}>
          <div className={cnAsideContent("Header")}>
            <h2>{t("aside_right-Information")}</h2>
          </div>
          <div className={cnAsideContent("Description")}>
            <p>{t("customize_aside_text")}</p>
          </div>
          <div className={cnAsideContent("Action")}>
            <Button theme="danger-outline" width="100%" icon="icon-refresh" onClick={handleResetAll}>
              {t("customize_btn_reset_to_default_all")}
            </Button>
          </div>
        </div>
      </Aside>

      {logoSrc && (
        <ImageCropper
          image={logoSrc}
          aspect={4.25 / 1}
          cropMaxSize={{ height: 80, width: 340 }}
          handleClose={() => setLogoSrc(null)}
          onChangeImage={e => onChangeImage(e, "logo")}
          setImageValue={closeCroppingModal}
          type={imageType}
        />
      )}

      {bgSrc && (
        <ImageCropper
          image={bgSrc}
          aspect={16 / 9}
          cropMaxSize={{ height: 900, width: 1600 }}
          handleClose={() => setBgSrc(null)}
          onChangeImage={e => onChangeImage(e, "bg")}
          setImageValue={closeCroppingModal}
          type={imageType}
        />
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = ({ settingsTest: { customize } }: RootState) => ({
  customize,
});

const mapDispatchToProps = {
  setCustomize,
  setHeaderIcon,
  setHeaderTitle,
};

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<payloadSetCustomize>({
    form: FORM_CUSTOMERS,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(Customize);

// export default connect(mapStateToProps, mapDispatchToProps)(Customize);
