/* eslint-disable prefer-arrow-callback */
import JSZip from "jszip";
import React, { FC, useState, useEffect, useRef } from "react";
import generator from "generate-password";
import { Button, Icon, Checkbox } from "ss-ui";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { FormInput, FormSwitch, cnField } from "components/Form";
import { cnModal, Modal, LoadingContainer } from "components/Generic";

import {
  Validation,
  FORM_BACKUP,
  copyToClipboard,
  http,
  testPasswordBackUp,
  updateOnInputString,
  removeSpaces,
} from "core";

import { cnSettings } from "pages/Settings";

import { FormValues, Payload as PayloadSetBackUpParams } from "store/routines/settings/setBackUpParams";
import { Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { Payload as PayloadSetAutoBackupStatus } from "store/routines/settings/setAutoBackupStatus";
import { Payload as PayloadSetBackUpNotify } from "store/routines/settings/setBackUpNotify";
import { RootState } from "store/reducers";
import { SettingsBackupState } from "store/reducers/settings/backup";
import { setBackUpParams, setBackUpClear, setAutoBackupStatus, setGlobalSettings, setBackUpNotify } from "store/actions";

type Props = InjectedFormProps<FormValues> &
  RouteComponentProps<undefined> & {
    backup: SettingsBackupState;
    autobackupNotificationError: boolean;
    autobackupNotificationSuccess: boolean;
    setAutoBackupStatus: (payload: PayloadSetAutoBackupStatus) => void;
    setBackUpClear: () => void;
    setBackUpParams: (payload: PayloadSetBackUpParams) => void;
    setGlobalSettings: (payload: PayloadGlobalSettings) => void;
    setBackUpNotify: (payload: PayloadSetBackUpNotify) => void
  };

const InHouseBackup: FC<Props> = ({
  backup,
  autobackupNotificationError,
  autobackupNotificationSuccess,
  handleSubmit,
  setAutoBackupStatus,
  setBackUpClear,
  setBackUpParams,
  setGlobalSettings,
  setBackUpNotify
}) => {
  const { t } = useTranslation();

  const autoBackupPasswordRef = useRef<HTMLInputElement>(null);
  const zip = new JSZip();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [valueAutoBackup, setValueAutoBackup] = useState<boolean>(false);
  const [autoBackupPasswordError, setAutoBackupPasswordError] = useState<boolean>(false);
  const [shownAutoBackupPassword, setShownAutoBackupPassword] = useState<boolean>(false);
  const [shownBackupPasswordModal, setShownBackupPasswordModal] = useState<boolean>(false);
  const [autoBackupPassword, setAutoBackupPassword] = useState<string>("");
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [saveButtonVisible, setSaveButtonVisible] = useState<boolean>(false);
  const [isLoadingGeneratePassword, setIsLoadingGeneratePassword] = useState<boolean>(false);


  useEffect(() => {
    setError(backup.error);
  }, [backup.error]);

  useEffect(() => {
    setValueAutoBackup(backup.autoBackup.enabled);
  }, [backup.autoBackup.enabled]);

  useEffect(() => {
    if (backup.modal) {
      generateFile("BackUpFiles/backup.php", "backup.php");
      generateFile("BackUpFiles/readme_de.txt", "readme_de.txt");
      generateFile("BackUpFiles/readme_en.txt", "readme_en.txt");
    }
  }, [backup.modal]);

  useEffect(() => {
    if (backup.autoBackup.password) {
      getDecryptBackupPassword(backup.autoBackup.password).then(({ password }) => {
        if (password) {
          setAutoBackupPassword(password);
        }
      });
    }
  }, [backup.autoBackup.password]);

  useEffect(() => {
    if (valueAutoBackup && autoBackupPasswordRef.current && !autoBackupPassword && !backup.autoBackup.password) {
      setAutoBackupPasswordError(true);
      autoBackupPasswordRef.current.focus();
    } else if (valueAutoBackup && autoBackupPassword) {
      setAutoBackupPasswordError(false);
    }
  }, [valueAutoBackup, autoBackupPassword]);

  const getDecryptBackupPassword = async (encryptPassword: string) => {
    return http({ route: "password/decrypt", method: "POST", payload: { password: encryptPassword } }) as any;
  };

  const getParamsForBackUP = () => {
    setOpen(!isOpen);
  };

  const handleUpdateAutoBackup = () => {
    setValueAutoBackup(!valueAutoBackup);
    setAutoBackupStatus({ status: !valueAutoBackup });
    setShowConfirmModal(false);
  };

  const handleGenerateUpdateBackupPassword = async () => {
    setIsLoadingGeneratePassword(true);
    const generatePasswordResult = generator.generate({
      length: 16,
      numbers: true,
      lowercase: true,
      uppercase: true,
      symbols: true,
      strict: true,
      exclude: "-_@!",
    });

    setAutoBackupPasswordError(false);
    setAutoBackupPassword(generatePasswordResult);
    setSaveButtonVisible(true);
    setTimeout(() => setIsLoadingGeneratePassword(false), 300);
  };

  const handleUpdateBackupPassword = async () => {
    if (autoBackupPassword && testPasswordBackUp(autoBackupPassword) === 1) {
      await setGlobalSettings({
        name: "AUTO_BACKUP_PASSWORD",
        value: autoBackupPassword,
      });
      setAutoBackupPasswordError(false);
      setSaveButtonVisible(false);
      toast.success(t("expire-window-success"));
    }

    if (autoBackupPassword && testPasswordBackUp(autoBackupPassword) === 0) {
      setAutoBackupPasswordError(true);
      toast.error(t("toast_error_auto_backup_password_dont_strong"), { toastId: "ERROR_AUTO_BACKUP_PASSWORD" });
    }

    if (autoBackupPassword && testPasswordBackUp(autoBackupPassword) === 2) {
      setAutoBackupPasswordError(true);
      toast.error(t("settings_auto_backup_error_password_length"), { toastId: "ERROR_AUTO_BACKUP_PASSWORD" });
    }
  };

  const handlePassword = (data: any) => {
    setBackUpParams({
      userPassword: data.password,
    });
  };

  const closeModal = () => {
    setBackUpClear();
    setOpen(false);
    setError(false);
  };

  const generateFile = (dir: string, name: string) => {
    fetch(dir)
      .then(res => res.arrayBuffer())
      .then(ab => {
        zip.file(name, ab, { binary: true });
      });
  };

  const downloadBackUpFile = () => {
    const textFile = `;Path for local backups\nFILEPATH="${backup.config.filepath}"\n\nUSERNAME="${backup.config.username}"\nPASSWORD="${backup.config.password}"\nAPPLICATION_ID="${backup.config.appId}"\nURL="${backup.config.url}"`;
    zip.file("config.ini", textFile);
    zip.generateAsync({ type: "blob" }).then(blob => {
      saveAs(blob, "Archive.zip");
    });
  };

  const handleTest = (error: boolean, success: boolean) => {
    setBackUpNotify({
      backUpSuccess: success,
      backUpError: error,
    })
  }

  const handleConfirmModal = () => {
    if (valueAutoBackup) {
      handleUpdateAutoBackup();
    } else {
      setShowConfirmModal(true);
    }
  };

  const copyValueInput = (value: string) => {
    copyToClipboard(value);
    toast.info(t("toast_default_copy"), { toastId: "DEFAULT_COPY" });
  };

  return (
    <>
      <div className={cnSettings("Container")}>
        <div className={cnSettings("SwitchItem")} id="container_auto_backup">
          <div className={cnSettings("Header")}>
            {t("settings_auto_backup_header")}
            <div className={cnSettings("Switch")}>
              <FormSwitch isOn={valueAutoBackup} handleToggle={handleConfirmModal} fieldName="autoBackup" />
              {valueAutoBackup ? (
                <p>{t("settings_switch_on")}</p>
              ) : (
                <p className={cnSettings("Switch-Disable")}>{t("settings_switch_off")}</p>
              )}
            </div>
          </div>
          <div className={cnSettings("Hint")}>{t("settings_auto_backup_description")}</div>
          <div className={cnSettings("Hint")}>{t("settings_auto_backup_description2")}</div>
          {valueAutoBackup && <Button onClick={getParamsForBackUP}>{t("settings_auto_backup_confirm_button_get_setup")}</Button>}
        </div>

        {valueAutoBackup && (
          <>

            <div className={cnSettings("Switch-Right")}>
              <Checkbox
                checked={autobackupNotificationError}
                id="checkbox_autobackup_notification_error"
                onChange={() => handleTest(!autobackupNotificationError, autobackupNotificationSuccess)}
              />
              <label htmlFor="checkbox_autobackup_notification_error">{t("settings_auto_backup_notify_error")}</label>
            </div>

            <div className={cnSettings("Switch-Right")}>
              <Checkbox
                checked={autobackupNotificationSuccess}
                id="checkbox_autobackup_notification_success"
                onChange={() => handleTest(autobackupNotificationError, !autobackupNotificationSuccess)}
              />
              <label htmlFor="checkbox_autobackup_notification_success">{t("settings_auto_backup_notify_success")}</label>
            </div>

            <div className={cnSettings("SwitchItem")}>
              <div className={cnSettings("Header")}>{t("settings_auto_backup_password")}</div>
              <div className={cnSettings("Hint")}>{t("settings_auto_backup_password-description")}</div>
              <div className={cnSettings("Fields", [cnSettings("AutoBackup")])}>
                <LoadingContainer isLoading={isLoadingGeneratePassword}>
                <div className={cnSettings("Field")}>
                  <div className={cnSettings("Field__value PassInput")}>
                    <div className={`${cnSettings("Field__password")} ${shownAutoBackupPassword ? "password" : "text"}`}>
                      <input className="fakePassport" type="password" name="fakepassword" />
                      <input
                        className={cnSettings("AutoBackupPassword", { error: autoBackupPasswordError })}
                        ref={autoBackupPasswordRef}
                        type={shownAutoBackupPassword ? "text" : "password"}
                        value={autoBackupPassword}
                        onChange={({ target: { value } }) => {
                          setAutoBackupPasswordError(!(value.length > 0));
                          setAutoBackupPassword(value);
                        }}
                        autoComplete="off"
                      />
                        <div className={cnSettings("Field__password--block")}>
                        <Icon
                          id="btn_settings_set_shown_auto_backup_app_id"
                          onClick={() => {
                            if (autoBackupPassword) {
                              copyToClipboard(autoBackupPassword);
                              toast.info(t("toast_auto_backup_password_copy"), { toastId: "PASSWORD_COPY" });
                            }
                          }}
                          name="icon-copy-pass"
                          width={16}
                          height={16}
                        />
                        <Icon
                          id="btn_settings_set_shown_auto_backup_app_id"
                          onClick={() => setShownAutoBackupPassword(!shownAutoBackupPassword)}
                          name={!shownAutoBackupPassword ? "icon-eye" : "icon-eye"}
                          width={20}
                          height={14}
                        />
                      </div>
                    </div>

                    <div className="action">
                      <Button
                        id="button_generate_key"
                        className={cnSettings("GenerateBackupPassword")}
                        onClick={handleGenerateUpdateBackupPassword}
                        theme="primary-link"
                        icon="icon-generate"
                        size={30}
                      >
                        {t("button-Generate")}
                      </Button>
                      {saveButtonVisible && (
                        <Button size={30} id="settings_auto_backup_btn_save" onClick={handleUpdateBackupPassword}>
                          {t("settings_auto_backup_btn_save")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                </LoadingContainer>
              </div>
            </div>
          </>
        )}
      </div>

      {isOpen && !backup.modal && (
        <Modal handleClose={closeModal} className={cnModal("ModalFolderSelect")}>
          <LoadingContainer isLoading={backup.isLoading}>
            <div className={cnModal("Header")}>{t("settings_auto_backup_login")}</div>
            <div className={cnModal("Scroll")}>
              <p>{t("settings_auto_backup_login_description")}</p>
              <Form onSubmit={handleSubmit(handlePassword)} id="submit-password">
                <Field
                  className={cnField("Input", { hasError: error })}
                  component={FormInput}
                  name="password"
                  isShown
                  placeholder={t("forgotPassword_field_password")}
                  type="password"
                  validate={[Validation.minLength8]}
                  onInput={(e: any) => updateOnInputString(e, removeSpaces)}
                />
                {error && <span className={cnSettings("AutoBackupPassword", { fieldError: error })}>{t("error_invalid_credentials")}</span>}
              </Form>
            </div>
            <div className={cnModal("Actions")}>
              <Button id="button_update_icon_confirm" type="submit" form="submit-password">
                {t("button-Confirm")}
              </Button>
              <Button id="button_update_icon_cancel" theme="secondary-outline" onClick={() => setOpen(!isOpen)}>
                {t("button-Cancel")}
              </Button>
            </div>
          </LoadingContainer>
        </Modal>
      )}

      {showConfirmModal && (
        <Modal handleClose={() => setShowConfirmModal(false)} className={cnModal("Confirmation")}>
          <div className={cnModal("Header")}>{t("settings_auto_backup_confirm_title")}</div>
          <div className={cnModal("Scroll")}>
            <p className={cnModal("ConfirmationText")}>
              <b>{t("expire-window-header")}: </b>
              {t("settings_auto_backup_confirm_warning")}<br/><br/>
              {t("settings_auto_backup_confirm_warning2")}
            </p>
            <p className={cnModal("ConfirmationText")}>
              <b>{t("expire-window-header2")}: </b>
              {t("settings_auto_backup_confirm_text")}
            </p>
          </div>
          <div className={cnModal("Actions")}>
            <Button id="button_action_delete" onClick={() => handleUpdateAutoBackup()}>
              {t("settings_auto_backup_confirm_button_activate")}
            </Button>
            <Button id="button_action_cancel" theme="secondary-outline" onClick={() => setShowConfirmModal(false)}>
              {t("button-Cancel")}
            </Button>
          </div>
        </Modal>
      )}

      {backup.modal && (
        <Modal handleClose={closeModal} className={cnModal("ModalFolderSelect")}>
          <div className={cnModal("Header")}>{t("settings_auto_backup_result_header")}</div>
          <div className={cnModal("Scroll")}>
            <p>{t("settings_auto_backup_result_description")}</p>
            <div className={cnSettings("BashCode")}>
              <span>{t("settings_auto_backup_result_curl")}</span>
              <div className={cnSettings("BashCode-Item")}>
                <div className={cnSettings("BashCode-Code")}>{backup.bashScript}</div>
                <div className={cnSettings("BashCode-Actions")}>
                  <Icon id="btn_settings_set_shown_auto_backup_app_id" onClick={() => copyValueInput(backup.bashScript)} name="icon-copy" />
                </div>
              </div>
              <span className={cnSettings("BashCode-Warning")}>{t("settings_auto_backup_result_curl_description")}</span>
            </div>

            <p className={cnSettings("BashCode-Header")}>{t("settings_auto_backup_result_script")}</p>

            <div className={cnSettings("BashCode")}>
              <span>{t("login_user_name")}</span>
              <div className={cnSettings("BashCode-Item")}>
                <div className={cnSettings("BashCode-Code")}>{backup.config.username}</div>
                <div className={cnSettings("BashCode-Actions")}>
                  <Icon
                    id="btn_settings_set_shown_auto_backup_app_id"
                    onClick={() => copyValueInput(backup.config.username)}
                    name="icon-copy"
                  />
                </div>
              </div>
            </div>
            <div className={cnSettings("BashCode")}>
              <span>{t("login_user_pass")}</span>
              <div className={cnSettings("BashCode-Item")}>
                <div className={cnSettings("BashCode-Code")}>
                  {shownBackupPasswordModal ? backup.config.password : "***************************"}
                </div>
                <div className={cnSettings("BashCode-Actions PassInputBlock")}>
                  <Icon
                    id="btn_settings_set_shown_auto_backup_app_id"
                    onClick={() => copyValueInput(backup.config.password)}
                    name="icon-copy-pass"
                  />
                  <div className={`${shownBackupPasswordModal ? "password" : "input"} iconBlock`}>
                    <Icon
                      id="btn_settings_set_shown_auto_backup_app_id"
                      onClick={() => setShownBackupPasswordModal(!shownBackupPasswordModal)}
                      name={!shownBackupPasswordModal ? "icon-eye" : "icon-eye"}
                      width={20}
                      height={14}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={cnSettings("BashCode")}>
              <span>App_id</span>
              <div className={cnSettings("BashCode-Item")}>
                <div className={cnSettings("BashCode-Code")}>{backup.config.appId}</div>
                <div className={cnSettings("BashCode-Actions")}>
                  <Icon
                    id="btn_settings_set_shown_auto_backup_app_id"
                    onClick={() => copyValueInput(backup.config.appId)}
                    name="icon-copy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={cnModal("Actions")}>
            <Button onClick={downloadBackUpFile}>{t("settings_auto_backup_download_script")}</Button>
            <Button id="button_action_cancel" theme="secondary-outline" onClick={closeModal}>
              {t("button-Cancel")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({
  settingsTest: { backup },
  settings: { autobackupNotificationError, autobackupNotificationSuccess }
}: RootState) => ({
  backup,
  autobackupNotificationError,
  autobackupNotificationSuccess,
});

export default compose<Props, Partial<Props>>(
  withRouter,
  connect(mapStateToProps, { setBackUpParams, setBackUpClear, setAutoBackupStatus, setGlobalSettings, setBackUpNotify }),
  reduxForm<FormValues>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_BACKUP,
  }),
)(InHouseBackup);
