/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { App } from "components/App";

import { unregister, isEnvDevelop, isDesktopBrowser, copyToDesktopClipboard } from "core";

import { configureStore } from "store";

import "locales/i18n";
import "index.scss";

import { version } from "../package.json";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

export const store = configureStore({ history });

if (isDesktopBrowser()) {
  const handleClipboardEvent = (event: ClipboardEvent) => {
    event.preventDefault();

    const selection = window.getSelection()?.toString();
    if (selection) {
      copyToDesktopClipboard(selection);

      if (event.type === "cut") {
        document.execCommand("delete");
      }
    }
  };

  document.addEventListener("copy", handleClipboardEvent);
  document.addEventListener("cut", handleClipboardEvent);
}

Sentry.captureException("err");

if (isEnvDevelop()) {
  Sentry.init({
    debug: isEnvDevelop(),
    dsn: "https://8e3e7372b16e4799b620f0f6a518a5fc@applog.securium.ch/6",
    tunnel: `${
      process.env.REACT_APP_API_URL || "/backpass/api/v2/"
    }/sentry/?sentry_key=8e3e7372b16e4799b620f0f6a518a5fc&sentry_version=7`.replace(/([^:]\/)\/+/g, "$1"),
    release: version,
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        // @ts-ignore
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0,
    beforeSend(event) {
      return event;
    },
    sendClientReports: false,
    autoSessionTracking: false,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("app-root"),
);

unregister();
