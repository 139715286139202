import * as msal from "@azure/msal-browser";
import React, { ChangeEvent, FC, useEffect, useMemo, useState, useRef } from "react";
import { Button, Icon, LanguageSelectField } from "ss-ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { Aside, cnAsideContent, Content, ContentWrapper } from "components/Layout";
import { ImageCropper, LoadingContainer, ResetPassword } from "components/Generic";
import {
  getKey,
  http,
  LOCAL_STORAGE,
  mapRolesName,
  removeDataFromLS,
  ROLE_TYPE,
  Routes,
  translation,
  typeFolder,
  IMicrosoftAuth,
  getExpandFoldersList,
  getSizeImage,
  USER_STORAGE,
} from "core";

import i18n, { getUserLocale } from "locales/i18n";
import { Payload as PayloadSetAvatar } from "store/routines/user/setAvatar";
import { RootState } from "store/reducers";
import { getAvatar, getProfile, logout, setAvatar, setHeaderIcon, setHeaderTitle } from "store/actions";

export const cnProfile = cn("Profile");

type Props = {
  avatar?: string;
  canUpdatePassword?: boolean;
  email?: string;
  error?: any;
  foldersList: typeFolder[];
  fullName?: string;
  getAvatar: () => void;
  getProfile: () => void;
  isLoading: boolean;
  isMicrosoft: boolean;
  logout: () => void;
  microsoftAuth: IMicrosoftAuth;
  role: ROLE_TYPE;
  setAvatar: (payload: PayloadSetAvatar) => void;
  setHeaderIcon: (payload: string) => void;
  setHeaderTitle: (payload: string) => void;
  username?: string;
  userStorage: USER_STORAGE;
};

const Profile: FC<Props> = ({
  avatar,
  canUpdatePassword,
  email,
  error,
  foldersList,
  fullName,
  getAvatar,
  getProfile,
  isLoading,
  isMicrosoft,
  logout,
  microsoftAuth,
  role,
  setAvatar,
  setHeaderIcon,
  setHeaderTitle,
  username,
  userStorage,
}) => {
  const { t } = useTranslation();

  const [errorMaxSize, setErrorMaxSize] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<any | null>(null);
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);
  const [resetPassModal, setResetPassModal] = useState<boolean>(false);
  const [imageType, setImageType] = useState<string>("image/png");

  const logoFileInput = useRef<HTMLInputElement | null>(null);

  const isAdmin: boolean = useMemo(() => [ROLE_TYPE.ADMIN].includes(role), [role]);
  const isStandardUser: boolean = useMemo(() => [ROLE_TYPE.FREE_USER, ROLE_TYPE.STANDARD_USER].includes(role), [role]);
  const isManagers: boolean = useMemo(() => [ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER].includes(role), [role]);
  const { countFolders = 0 } = useMemo(
    () =>
      getExpandFoldersList(foldersList.filter(e => e.type === "personal")).reduce(
        (acc: { countFolders: number }, folder: typeFolder) => ({
          countFolders: acc.countFolders + 1,
        }),
        { countFolders: 0 },
      ),
    [foldersList],
  );

  const personalKeysCount = foldersList
    .filter((e: typeFolder) => e.type === "personal" && e.root === null)
    .map((el: typeFolder) => el.countKeys);

  useEffect(() => {
    setHeaderIcon("user");
    setHeaderTitle(t("navigation_bottom-Profile"));
    getAvatar();
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLanguage = async (langCode: string) => {
    setIsLoadingLocal(true);
    try {
      await i18n.changeLanguage(langCode);
      await http({
        route: `user/locale/${langCode}`,
      });
    } catch ({ response: { data } }) {
      if (get(data, "error", "") === "account_is_blocked") {
        logout();
      }
    } finally {
      setIsLoadingLocal(false);
    }
  };

  const logoutAzure = () => {
    removeDataFromLS(LOCAL_STORAGE.USER);
    new msal.PublicClientApplication({
      auth: {
        clientId: microsoftAuth.client_id || "",
        authority: `https://login.microsoftonline.com/${microsoftAuth.tenant_id || ""}`,
        redirectUri: `${window.location.origin}${Routes.Login}`,
      },
    }).logout();
  };

  // * Upload and cropping avatar
  const handleUpload = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize: number = 5;
    const reader = new FileReader();

    if (files && files[0]) {
      if (files[0].size / 1000000 > maxFileSize) {
        toast.error(t("validate_avatar_size_limit_mb", { size: maxFileSize }));
        setErrorMaxSize(true);
      } else if (!["image/jpeg", "image/png", "image/jpg"].includes(files[0].type)) {
        toast.error(t("validate_avatar_format"));
        setErrorType(true);
      } else {
        setErrorMaxSize(false);
        setErrorType(false);
        setImageType(files[0].type);
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          const imgUrl = reader.result;
          setImageSrc(imgUrl);
        };
      }
      resetRefs();
    }
  };

  const resetRefs = () => {
    if (logoFileInput.current) {
      logoFileInput.current.value = "";
    }
  };

  const onChangeImage = (value: string) => {
    if (getSizeImage(value) > 500) {
      toast.error(t("validate_avatar_size_limit_kb", { size: "500" }));
      setErrorMaxSize(true);
      setImageSrc(null);
    } else {
      setAvatar({
        avatar: value,
      });
      setImageSrc(null);
    }
  };

  return (
    <ContentWrapper id="profile_content_wrapper">
      <LoadingContainer isLoading={isLoading || isLoadingLocal}>
        <Content id="profile_content">
          <div className={cnProfile()}>
            <div className={cnProfile("Item")}>
              <div className={cnProfile("Avatar")}>
                {avatar ? (
                  <img src={avatar} alt="avatar" className={cnProfile("Avatar-Image")} />
                ) : (
                  <Icon name="icon-user-circle" fill="1A90BB" width={165} height={165} />
                )}
              </div>
              <label className={cnProfile("Upload")}>
                <Icon name="icon-upload" width={16} height={16} fill="#727272" />
                <div className="text">{t("button-Upload")}</div>
                <input type="file" ref={logoFileInput} name="avatar" onChange={handleUpload} id="input_file_avatar" accept="image/*" />
              </label>
              <div className={cnProfile("Help")}>png {t("form_input-Or")} jpg</div>
              {errorMaxSize && <div className="error">{t("validate_avatar_size_limit_kb", { size: 500 })}</div>}
              {errorType && <div className="error">{t("validate_avatar_format")}</div>}
              {error && <div className="error">{error}</div>}
              {imageSrc && (
                <ImageCropper
                  image={imageSrc}
                  cropArea={true}
                  handleClose={() => setImageSrc(null)}
                  onChangeImage={onChangeImage}
                  cropMaxSize={{ height: 165, width: 165 }}
                  cropMinSize={{ height: 91, width: 91 }}
                  aspect={1 / 1}
                  setImageValue={setImageSrc}
                  type={imageType}
                />
              )}
            </div>
            <div className={cnProfile("Item")}>
              {fullName && (
                <>
                  <div className={cnProfile("Field")}>
                    <div className={cnProfile("Field-Label")}>{t("profile_full_name_header")}</div>
                    <div className={cnProfile("Field-Value")}>{isStandardUser ? username : fullName}</div>
                  </div>
                  <div className={cnProfile("Field")}>
                    <div className={cnProfile("Field-Label")}>{t("table_title-Role")}</div>
                    <div className={cnProfile("Field-Value")}>{mapRolesName[role]}</div>
                  </div>
                </>
              )}
              {email && (
                <div className={cnProfile("Field")}>
                  <div className={cnProfile("Field-Label")}>{t("form_input-Email")}</div>
                  <div className={cnProfile("Field-Value")}>{email}</div>
                </div>
              )}
              {!isStandardUser && (
                <>
                  {userStorage === USER_STORAGE.LOCAL || (isAdmin && canUpdatePassword) ? (
                    <div className={cnProfile("ResetPassword")}>
                      <Button
                        id="button_change_password"
                        icon="icon-pencil"
                        width="100%"
                        theme="secondary-outline"
                        onClick={() => setResetPassModal(true)}
                      >
                        {t("change_password")}
                      </Button>
                    </div>
                  ) : null}
                </>
              )}

              <div className={cnProfile("Field")}>
                <div className={cnProfile("Field-Label")}>{t("language_label")}</div>
                <LanguageSelectField
                  currentLanguage={{ code: getUserLocale(), title: t(`language_${getUserLocale()}`) }}
                  setLanguage={({ code }) => handleChangeLanguage(code)}
                  languages={translation.languages}
                />
              </div>
              <div className={cnProfile("Logout")}>
                {!isMicrosoft && (
                  <Button id="button_profile_logout" onClick={logout} theme="danger-outline" icon="icon-power" width="100%">
                    {t("button-Logout")}
                  </Button>
                )}
                {isMicrosoft && (
                  <Button id="button_profile_logout" onClick={logout} theme="danger-outline" icon="app/pass-logo" width="100%">
                    {t("button-logout-close-passsecurium")}
                  </Button>
                )}
                <div className={cnProfile("Field")} />
                {isMicrosoft && (
                  <Button
                    id="button_profile_logout_microsoft"
                    onClick={logoutAzure}
                    theme="danger-outline"
                    icon="colored/icon-azure"
                    width="100%"
                  >
                    {t("button-logout-sign-out-microsoft")}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {resetPassModal && (
            <div className="Unsubscribe">
              <ResetPassword onChange={value => setResetPassModal(value)} isForcibly={false} />
            </div>
          )}
        </Content>
        <Aside side="right" id="profile_aside_right">
          <div className={cnAsideContent()}>
            <div className={cnAsideContent("Header")}>
              <h2>{t("aside_right-Information")}</h2>
            </div>
            <div className={cnAsideContent("Description")}>
              <p>{t("aside_right_text-Profile")}</p>
            </div>

            <div className={cnAsideContent("Total")}>
              <div className={cnAsideContent("Total-Count")}>
                <span>{countFolders}</span>
                <p>{t("folders-personal")}</p>
              </div>
              <div className={cnAsideContent("Total-Count")}>
                <span>{Number(personalKeysCount)}</span>
                <p>{t("key-personal")}</p>
              </div>
            </div>

            {isManagers && (
              <div className={cnAsideContent("Action")}>
                <Link
                  id={getKey("link_to_folder_manager")}
                  to={{
                    pathname: Routes.FolderManagement,
                    hash: "#personal",
                  }}
                >
                  <Button id="button_profile_edit" width="100%" icon="icon-pencil" theme="secondary-outline">
                    {t("button-EditPerson")}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </Aside>
      </LoadingContainer>
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  folders: { list: foldersList },
  settings: { microsoftAuth, canUpdatePassword, userStorage },
  user: { avatar, isLoading, error, email, role, isMicrosoft, fullName, username },
}: RootState) => ({
  avatar,
  canUpdatePassword,
  email,
  error,
  foldersList,
  fullName,
  isLoading,
  isMicrosoft,
  microsoftAuth,
  role,
  username,
  userStorage,
});

const mapDispatchToProps = {
  getAvatar,
  getProfile,
  logout,
  setAvatar,
  setHeaderIcon,
  setHeaderTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
