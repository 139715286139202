/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, memo } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

import { version } from "../../../../package.json";

const cnFooter = cn("Footer");

type Props = {
  customize: SettingsCustomizeState;
};

const Footer: FC<Props> = ({ customize }: Props) => {
  const { t } = useTranslation();
  return (
    <footer className={cnFooter()} id="page_footer">
      <div className={cnFooter("Content")} id="page_footer_content">
        <div className={cnFooter("Info")} id="page_footer_content_info">
          <p id="page_footer_version">
            PassSecurium
            <span>v{version}</span>
          </p>
          <span className="copywriting">
            {customize.companyName ? (
              `© ${customize.companyName.replace("%year%", String(new Date().getFullYear()))}`
            ) : (
              <>
                © 2016-{new Date().getFullYear()} {t("footer-name")}
              </>
            )}
          </span>
        </div>
          <div className={cnFooter("Download")}>
            <p className={cnFooter("Download-Text")} id="page_footer_app_text">
              {t("footer-downloads-1")}
              <a href="https://apps.apple.com/app/id1159065748" target="_blank" id="page_footer_text_link_ios">
                iPhone®, iPad®
              </a>
              {t("footer-downloads-2")}
              <a
                href="https://play.google.com/store/apps/details?id=ch.alpeinsoft.passsecurium.abo"
                target="_blank"
                id="page_footer_text_link_ios"
              >
                Android™
              </a>
            </p>
            <div className={cnFooter("Download-Link")}>
              <a href="https://apps.apple.com/app/id1159065748" target="_blank" id="page_footer_link_ios">
                <Icon name="icon-ios" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=ch.alpeinsoft.passsecurium.abo"
                target="_blank"
                id="page_footer_link_android"
              >
                <Icon name="icon-android" />
              </a>
            </div>
          </div>
      </div>
    </footer>
  );
};

const mapStateToProps = ({ settingsTest: { customize } }: RootState) => ({
  customize,
});

const mapDispatchToProps = {};

export default memo(connect(mapStateToProps, mapDispatchToProps)(Footer));
