import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";
import { logout } from "store/actions";

export type Payload = {
  method: string;
  token: string;
};

export const action: Routine = createRoutine("SET_UNSUBSCRIBE");

const setUnsubscribe = ({ method, token }: Payload) => {
  if (method === "delete") {
    return http({
      route: `user/cancellation/${token}`,
      method: "DELETE",
    });
  }
  return http({
    route: `user/cancellation/${token}/approve`,
    method: "POST",
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setUnsubscribe, payload);
    toast.success(i18n.t("toast_unsubscribe_success"));

    if (payload.method === "post") {
      yield put(logout());
    }

    yield put(action.success(response));
  } catch (error) {
    toast.warning(i18n.t("toast_unsubscribe_error"));
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
