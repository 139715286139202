import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet, snakeCase } from "lodash";
import { toast } from "react-toastify";

import { http } from "core";

import { folderGetAll, keyFavorite } from "store/actions";

import i18n from "locales/i18n";

export type Payload = {
  keyID: string;
  folderID: string;
};

export const action: Routine = createRoutine("FAVORITE_SET_KEY");

const get = ({ folderID, keyID }: Payload) => {
  return http({ route: `user/key/${keyID}/folder/${folderID}/favorite` });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(get, payload);

    toast.success(i18n.t(`toast_key_success_${response.favorite ? "set" : "unset"}_favorite`));

    yield put(action.success({ keyID: payload.keyID, status: response.favorite }));
    yield put(keyFavorite.trigger({ keyID: payload.keyID, status: response.favorite }));
  } catch (error) {
    const errorMessage = lodashGet(error, "response.data.detail.messages[0]", undefined);

    if (errorMessage) {
      toast.error(i18n.t(`toast_folder_error_${snakeCase(errorMessage)}`));
      yield put(folderGetAll.trigger());

      yield put(action.failure(errorMessage));
    } else {
      yield put(action.failure(lodashGet(error, "message", undefined)));
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
