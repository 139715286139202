import React, { FC, useState, ChangeEvent, useContext, useEffect, useRef } from "react";
import { Icon } from "ss-ui";
import { getSizeImage, FIELD_TYPE } from "core";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { ImageCropper, ButtonDiv } from "components/Generic";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { FieldContext } from "./Types";

const fieldType = FIELD_TYPE.LOGO;

const FieldLogo: FC = () => {
  const { t } = useTranslation();

  const context = useContext(FieldContext);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageType, setImageType] = useState<string>("image/png");

  const [errors, setErrors] = useState({
    maxSize: false,
    type: false,
  });

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else if (context.key?.[fieldType]) {
      handleChange(context.key[fieldType]);
    }
  }, [context.key]);

  const handleRemove = () => {
    context.handleChange(fieldType, "");
    setImageSrc(null);
    setCroppedImage(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUpload = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize = 5;
    const reader = new FileReader();

    if (files && files[0]) {
      const file = files[0];

      if (file.size / 1000000 > maxFileSize) {
        toast.error(t("validate_avatar_size_limit_kb", { size: "500" }));
        setErrors(prev => ({ ...prev, maxSize: true }));
        return;
      }

      if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
        toast.error(t("validate_avatar_format"));
        setErrors(prev => ({ ...prev, type: true }));
        return;
      }

      setErrors({ maxSize: false, type: false });
      setImageType(file.type);
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImageSrc(reader.result as string);
      };
    }
  };

  const handleChange = (value: string) => {
    if (getSizeImage(value) > 500) {
      toast.error(t("validate_avatar_size_limit_kb", { size: "500" }));
      setErrors(prev => ({ ...prev, maxSize: true }));
      context.handleChange(fieldType, null);
      setImageSrc(null);
      setCroppedImage(null);
    } else {
      context.handleChange(fieldType, value);
      setCroppedImage(value);
    }
  };

  return (
    <div className={cnManageKey("Logo")}>
      <div className="Field">
        <span className="label">{t("form_input-Logo")}</span>
      </div>
      {croppedImage && (
        <div className={cnManageKey("Logo-Image")}>
          <img alt="" src={croppedImage} />
        </div>
      )}
      {croppedImage ? (
        <ButtonDiv id="btn_remove_icon" className={cnManageKey("Upload Remove-Logo")} onClick={handleRemove}>
          <Icon name="icon-trash" width={16} height={16} />
          <div className="text">{t("tooltip-delete")}</div>
        </ButtonDiv>
      ) : (
        <label className={cnManageKey("Upload")}>
          <Icon name="icon-upload" width={16} height={16} />
          <div className="text">{t("button-Attach")}</div>
          <input
            ref={fileInputRef}
            onChange={handleUpload}
            type="file"
            name={fieldType}
            id="input_file_key_avatar"
            accept="image/*"
          />
        </label>
      )}

      {errors.maxSize && <div className="error">{t("validate_avatar_size_limit_kb", { size: 500 })}</div>}
      {errors.type && <div className="error">{t("validate_avatar_format")}</div>}

      {imageSrc && (
        <div className={cnManageKey("Crop")}>
          <ImageCropper
            aspect={1 / 1}
            cropArea={false}
            cropMaxSize={{ height: 150, width: 150 }}
            handleClose={() => {
              setImageSrc(null);

              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            }}
            image={imageSrc}
            onChangeImage={handleChange}
            setImageValue={setImageSrc}
            type={imageType}
          />
        </div>
      )}
    </div>
  );
};

export default FieldLogo;
